import React from 'react';
import { useBreakpoints } from 'react-device-breakpoints';

import styles from './Privacy.module.scss';
import { useTranslation } from 'react-i18next';

const Privacy = ({ right = null }) => {
  const { t } = useTranslation();
  const { isMobile } = useBreakpoints();

  const alignStyle =
    right && !isMobile
      ? {
        float: 'right'
      }
      : {};

  return (
    <div className={styles.privacyWrapper} style={alignStyle}>
      <a
        href={t('common.privacy.url')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('common.privacy.label')}
      </a>
      <a
        href={t('common.imprint.url')}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t('common.imprint.label')}
      </a>
    </div>
  );
};

export default Privacy;
