import React from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import styles from './DesktopHome.module.scss';
import Privacy from '../../../components/Privacy/Privacy';

const DesktopHome = ({ history }) => {
  const { t } = useTranslation();

  const goToQuiz = () => history.push('/quiz');
  return (
    <div className={styles.imageWrapper}>
      <div className={styles.textWrapper}>
        <p>{t('common.welcomeSlug')}</p>

        <button className="basicButton" onClick={goToQuiz}>
          {t('quiz.start')}
        </button>
        <Privacy />
      </div>
    </div>
  );
};

export default withRouter(DesktopHome);
