import React, { createContext } from 'react';
import useAnswers from './useAnswers';

export const AnswersContext = createContext({});

const AnswersProvider = ({ children }) => {
  const value = useAnswers();
  return (
    <AnswersContext.Provider value={value}>{children}</AnswersContext.Provider>
  );
};

export default AnswersProvider;
