import React from 'react';
import { Route, Switch } from 'react-router-dom';
import throttle from 'lodash/fp/throttle';
import './App.scss';
import Home from './container/Home';
import Results from './container/Results';
import Quiz from './container/Quiz';

function App() {
  const onWindowResize = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };
  onWindowResize();

  const throttled = throttle(500, onWindowResize);

  window.addEventListener('resize', throttled);

  return (
    <Switch>
      <Route path="/" component={Home} exact />
      <Route path="/results" component={Results} exact />
      <Route path="/quiz" component={Quiz} exact />
    </Switch>
  );
}

export default App;
