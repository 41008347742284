import React, { useState, useContext } from 'react';
import Question from '../../components/Question/Question';
import { AnswersContext } from '../../hooks/AnswerContext';
import { useTranslation } from 'react-i18next';

import styles from './Quiz.module.scss';
import LogoWrapper from '../../components/LogoWrapper';

const Quiz = ({ history }) => {
  const { t } = useTranslation();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const { score, questions } = useContext(AnswersContext);

  const goToResults = () => history.push('/results');

  return (
    <>
      <LogoWrapper desktopOnly />
      {questions.length > 0 && (
        <Question
          question={questions[currentQuestionIndex]}
          key={questions[currentQuestionIndex].title}
          questionIndex={currentQuestionIndex}
        >
          <p className={styles.alignRight}>
            {currentQuestionIndex < questions.length - 1 ? (
              <button
                className="basicButton"
                onClick={() =>
                  setCurrentQuestionIndex(currentQuestionIndex + 1)
                }
              >
                {t('common.nextQuestion')}
              </button>
            ) : (
              <button className="basicButton" onClick={() => goToResults()}>
                {t('common.results')}
              </button>
            )}
          </p>
        </Question>
      )}
      <p style={{ display: 'none' }}>Debug: Current score is {score}</p>
    </>
  );
};

export default Quiz;
