import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Breakpoints } from 'react-device-breakpoints';
import { I18nextProvider } from 'react-i18next';
import './index.scss';
import App from './App.jsx';
import i18n from './i18n';
import AnswersProvider from './hooks/AnswerContext';

const breakpoints = {
  isMobile: '(max-width: 767px)'
};

i18n.init().then(() => {
  ReactDOM.render(
    <Breakpoints {...breakpoints}>
      <I18nextProvider i18n={i18n}>
        <Router>
          <Suspense fallback={<p>Loading</p>}>
            <AnswersProvider>
              <App />
            </AnswersProvider>
          </Suspense>
        </Router>
      </I18nextProvider>
    </Breakpoints>,
    document.getElementById('root')
  );
});
