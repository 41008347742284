import React, { useContext } from 'react';
import cx from 'classnames';

import { ANSWER_VALUES } from '../../services/QuestionService';
import styles from './Answer.module.scss';
import { AnswersContext } from '../../hooks/AnswerContext';

const Answer = ({ questionId, answer, wide = false }) => {
  const { chosenAnswers, setChosenAnswer } = useContext(AnswersContext);

  const onAnswerClick = () => {
    setChosenAnswer(questionId, answer.id, answer.value);
  };
  return (
    <>
      <button
        className={cx({
          [styles['active']]:
            chosenAnswers[questionId] &&
            chosenAnswers[questionId][answer.id] !== undefined,
          [styles['wide']]: wide,
          [styles['short']]: !wide,
          [styles['wrongSelected']]:
            chosenAnswers[questionId] &&
            chosenAnswers[questionId][answer.id] !== undefined &&
            answer.value === ANSWER_VALUES.INCORRECT
        })}
        onClick={onAnswerClick}
      >
        {answer.title}
      </button>
    </>
  );
};

export default Answer;
