import React from 'react';
import { useBreakpoints } from 'react-device-breakpoints';

import LogoWrapper from '../../components/LogoWrapper';
import LanguageSelect from '../../components/LanguageSelect';
import MobileHome from './MobileHome';
import DesktopHome from './DesktopHome';

const Home = () => {
  const { isMobile } = useBreakpoints();

  return (
    <>
      <LogoWrapper desktopOnly />
      {isMobile ? <MobileHome /> : <DesktopHome />}

      <LanguageSelect />
    </>
  );
};

export default Home;
