import React from 'react';
import cx from 'classnames';
import { withRouter } from 'react-router-dom';

import styles from './LogoWrapper.module.scss';

const LogoWrapper = ({ history, desktopOnly = false }) => (
  <div className={cx(desktopOnly ? styles['desktopOnly'] : '')}>
    <div
      className={styles.logoWrapper}
      onClick={() => history.push('/')}
      role="presentation"
    >
      <img src="/images/logo.png" alt="Hirslanden Logo" />
    </div>
  </div>
);

export default withRouter(LogoWrapper);
