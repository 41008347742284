import { DEFAULT_LANGUAGE } from '../i18n';

export const ANSWER_VALUES = {
  CORRECT: 1,
  INCORRECT: 0
};

let lastId = 0;
// Appends a property `id` to each question object and it's possibleAnswers
const appendIds = questions =>
  questions.map(question => ({
    ...question,
    // eslint-disable-next-line
    id: lastId++,
    possibleAnswers: question.possibleAnswers.map(answer => ({
      ...answer,
      // eslint-disable-next-line
      id: lastId++
    }))
  }));

const getAll = async (locale = DEFAULT_LANGUAGE) => {
  const { default: questions } = await import(
    `../locales/${locale.substr(0, 2)}/quiz.js`
  );

  return appendIds(questions);
};

const QuestionService = {
  getAll
};

export default QuestionService;
