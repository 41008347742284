import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import styles from './MobileHome.module.scss';
import Privacy from '../../../components/Privacy/Privacy';

const backgroundStyles = [
  { imageId: 1, long: false, backgroundPosition: '-10px center' },
  { imageId: 10, long: true, backgroundPosition: '-75px center' },
  { imageId: 3, long: true, backgroundPosition: '-65px center' },
  { imageId: 9, long: false, backgroundPosition: 'center' }
].map(({ imageId, long, backgroundPosition }) => ({
  backgroundImage: `url(${window.location.origin}/images/hirslanden-F${imageId}.jpeg)`,
  height: long
    ? 'calc(var(--vh, vh) * 60 - 10px)'
    : 'calc(var(--vh, vh) * 40 - 10px)',
  backgroundPosition
}));

const MobileHome = ({ history }) => {
  const { t } = useTranslation();

  const goToQuiz = () => history.push('/quiz');

  return (
    <>
      <div className={styles.images}>
        <div className={styles.imageColumn}>
          <div
            className={styles.imageWrapper}
            style={backgroundStyles[0]}
          ></div>
          <div
            className={styles.imageWrapper}
            style={backgroundStyles[1]}
          ></div>
        </div>
        <div className={styles.imageColumn} id={styles.secondColumn}>
          <div
            className={styles.imageWrapper}
            style={backgroundStyles[2]}
          ></div>
          <div
            className={styles.imageWrapper}
            style={backgroundStyles[3]}
          ></div>
        </div>
      </div>

      <div className={styles.images} id={styles.imagesShadow}>
        <div className={styles.iconContainer}>
          <img src="/images/icon.png" alt="Hirslanden Logo" />
        </div>
      </div>

      <div className={styles.textWrapper}>
        <p>{t('common.welcomeSlug')}</p>

        <button className="basicButton" onClick={goToQuiz}>
          {t('quiz.start')}
        </button>
      </div>
      <div style={{ paddingLeft: '25px' }}>
        <Privacy />
      </div>
    </>
  );
};

export default withRouter(MobileHome);
