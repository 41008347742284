import { useState, useEffect } from 'react';
import QuestionService, { ANSWER_VALUES } from '../services/QuestionService';
import i18n from '../i18n';

function useAnswers() {
  const [chosenAnswers, setAnswers] = useState({});
  const [score, setScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    const getQuestions = async () => {
      const questions = await QuestionService.getAll(i18n.language);
      setQuestions(questions);
      setMaxScore(
        questions.reduce(
          (total, q) =>
            total + q.possibleAnswers.reduce((acc, a) => acc + a.value, 0),

          0
        )
      );
    };
    getQuestions();
  }, []);

  const resetAnswers = () => {
    setAnswers({});
  };

  const overrideAnswers = (
    chosenAnswers,
    questionId,
    answerId,
    answerValue
  ) => {
    setAnswers({
      ...chosenAnswers,
      [questionId]: { [answerId]: answerValue }
    });
  };

  const setChosenAnswer = (questionId, answerId, answerValue) => {
    // Check how many answers are correct for a question
    const accordingQuestion = questions.find(q => q.id === questionId);
    const correctCount = accordingQuestion.possibleAnswers.reduce(
      (acc, answer) => (answer.value === ANSWER_VALUES.CORRECT ? acc + 1 : acc),
      0
    );
    const hasMultipleCorrect = correctCount > 1;

    if (hasMultipleCorrect) {
      if (chosenAnswers[questionId] === undefined) {
        // Answer is new to question
        overrideAnswers(chosenAnswers, questionId, answerId, answerValue);
      } else {
        // Question already answered
        const newAnswers = { ...chosenAnswers };

        if (newAnswers[questionId][answerId] === undefined) {
          newAnswers[questionId][answerId] = answerValue;
        } else {
          delete newAnswers[questionId][answerId];
        }

        setAnswers(newAnswers);
      }
    } else {
      // Always override answer if question has only one correct answer
      overrideAnswers(chosenAnswers, questionId, answerId, answerValue);
    }
  };

  useEffect(() => {
    const newScore = Object.values(chosenAnswers)
      .map(answerObject => Object.values(answerObject))
      .reduce(
        (total, answerScore) =>
          answerScore.length === 0
            ? total
            : total + Number(answerScore.reduce((prev, score) => prev + score)),
        0
      );

    setScore(newScore);
  }, [chosenAnswers]);

  return {
    chosenAnswers,
    score,
    setChosenAnswer,
    resetAnswers,
    questions,
    maxScore
  };
}

export default useAnswers;
