import React, { useContext } from 'react';
import { AnswersContext } from '../../hooks/AnswerContext';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import styles from './Results.module.scss';
import LogoWrapper from '../../components/LogoWrapper';
import Privacy from '../../components/Privacy/Privacy';

const Results = () => {
  const { t } = useTranslation();
  const { score, maxScore, resetAnswers } = useContext(AnswersContext);

  const restart = () => {
    resetAnswers();
  };

  const scoreText = t('quiz.resultReached', { score, maxScore });

  return (
    <>
      <LogoWrapper />
      <div className={styles.imageWrapper}>
        <div className={styles.textWrapper}>
          <p
            className={styles.boldText}
            dangerouslySetInnerHTML={{
              __html:
                score >= 5 ? t('quiz.resultCongrats') + scoreText : scoreText
            }}
          ></p>
          <p
            dangerouslySetInnerHTML={{
              __html: t('quiz.resultOutro')
            }}
          ></p>

          <Link onClick={restart} to="/quiz" className={styles.restartLink}>
            {t('quiz.restart')}
            <span className={styles.arrowRight}></span>
          </Link>
          <Privacy right />
        </div>
      </div>
    </>
  );
};

export default Results;
