import React from 'react';
import Answer from '../Answer';

import styles from './Question.module.scss';

const Question = ({ question: questionContent, questionIndex, children }) => {
  const backgroundStyle = {
    backgroundImage: `url('${
      window.location.origin
    }/images/hirslanden-F${Number(questionIndex + 1)}.jpeg')`
  };

  return (
    <>
      <div className={styles.questionWrapper}>
        <div className={styles.imgWrapper} style={backgroundStyle}></div>
        <div className={styles.contentWrapper}>
          <h2 className={styles.questionTitle}>
            {questionIndex + 1}. {questionContent.title}
          </h2>
          <div className={styles.answerWrapper}>
            {questionContent.possibleAnswers.map(answer => (
              <Answer
                questionId={questionContent.id}
                answer={answer}
                key={answer.id}
                wide={questionContent.wide}
              ></Answer>
            ))}
          </div>
          {children}
        </div>
      </div>
    </>
  );
};

export default Question;
